import { createSlice } from '@reduxjs/toolkit';

import { fetchSettings, togglePreloading } from './setting';
import { request } from '../../services/http';
import { USER_API } from '../../services/api';

export const fetchAuthUser = () => async dispatch => {
	try {
		const rs = await request(USER_API);
		const { user } = rs.result;
		const permissions = user.roles.reduce(
			(permissions, item) => [...permissions, ...item.permissions],
			[]
		);
		dispatch(loginUser(user));
		dispatch(setPermissions(permissions));
		dispatch(togglePreloading(false));
		dispatch(fetchSettings());
	} catch (e) {
		dispatch(signOut());
		dispatch(togglePreloading(false));
	}
};

const initialState = {
	account: null,
	loggedIn: false,
	permissions: [],
};

export const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		loginUser: (state, action) => {
			state.account = action.payload;
			state.loggedIn = true;
		},
		setUser: (state, action) => {
			state.account = action.payload;
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
		signOut: () => {
			return initialState;
		},
	},
});

export const { loginUser, setUser, setPermissions, signOut } =
	accountSlice.actions;

export default accountSlice.reducer;
