import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from '../hooks/auth';
import { loginUser, setPermissions } from '../redux/slices/user';
import { TOKEN_COOKIE, TOKEN_EXPIRATION } from '../services/constants';
import LocalStorage from '../services/storage';
import { fetchSettings } from '../redux/slices/setting';

const storage = new LocalStorage();

const AuthProvider = ({ children }) => {
	const user = useSelector(state => state.user.account);

	const dispatch = useDispatch();

	const setTokens = data => {
		const user = data.user;
		const permissions = user.roles.reduce(
			(permissions, item) => [...permissions, ...item.permissions],
			[]
		);
		dispatch(loginUser(user));
		dispatch(setPermissions(permissions));

		if (data.access_token) {
			storage.setItem(TOKEN_COOKIE, data.access_token);
			storage.setItem(TOKEN_EXPIRATION, data.expires_in);

			// fetch settings
			setTimeout(() => dispatch(fetchSettings()), 5000);
		}
	};

	return (
		<AuthContext.Provider value={{ user, setAuthUser: setTokens }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
