import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';

import Dropdown from '../scripts/dropdown';
import DropdownToggle from '../scripts/dropdown/DropdownToggle';
import DropdownMenu from '../scripts/dropdown/DropdownMenu';
import bell from '../assets/images/svg/bell.svg';
import avatar2 from '../assets/images/users/avatar-2.jpg';
import avatar3 from '../assets/images/users/avatar-3.jpg';

const NotificationDropdown = () => {
	const [items] = useState([]);
	const [newItems] = useState(0);
	const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);

	const toggleNotificationDropdown = () => {
		setIsNotificationDropdown(!isNotificationDropdown);
	};

	return (
		<Dropdown
			isOpen={isNotificationDropdown}
			toggle={toggleNotificationDropdown}
			className="topbar-head-dropdown ms-1 header-item">
			<DropdownToggle
				type="button"
				tag="button"
				className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
				<i className="bx bx-bell fs-22"></i>
				<span
					className={`position-absolute topbar-badge fs-10 translate-middle badge rounded-pill ${newItems === 0 ? 'bg-success' : 'bg-danger'}`}>
					{newItems}
					<span className="visually-hidden">unread messages</span>
				</span>
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
				<div className="dropdown-head bg-primary bg-pattern rounded-top">
					<div className="p-3">
						<div className="align-items-center row">
							<div className="col">
								<h6 className="m-0 fs-16 fw-semibold text-white">
									Notifications
								</h6>
							</div>
							{newItems > 0 && (
								<div className="col-auto dropdown-tabs">
									<span className="badge bg-light text-body fs-13">
										{newItems} New
									</span>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="tab-content">
					<div className="tab-pane py-2 ps-2 active">
						{items.length === 0 ? (
							<div className="p-4">
								<div className="w-25 w-sm-50 pt-3 mx-auto">
									<img src={bell} className="img-fluid" alt="user-pic" />
								</div>
								<div className="text-center pb-5 mt-2">
									<h6 className="fs-18 fw-semibold lh-base">
										Hey! You have no any notifications{' '}
									</h6>
								</div>
							</div>
						) : (
							<SimpleBar style={{ maxHeight: '300px' }} className="pe-2">
								<div className="text-reset notification-item d-block dropdown-item">
									<div className="d-flex">
										<img
											src={avatar3}
											className="me-3 rounded-circle avatar-xs"
											alt="user-pic"
										/>
										<div className="flex-grow-1">
											<div className="stretched-link">
												<h6 className="mt-0 mb-1 fs-13 fw-semibold">
													James Lemire
												</h6>
											</div>
											<div className="fs-13 text-muted">
												<p className="mb-1">
													We talked about a project on linkedin.
												</p>
											</div>
											<p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
												<span>
													<i className="mdi mdi-clock-outline"></i> 30 min ago
												</span>
											</p>
										</div>
										<div className="px-2 fs-15 z-2">
											<i
												className="mdi mdi-delete text-danger fs-20 cursor-pointer"
												onClick={() => {}}></i>
										</div>
									</div>
								</div>

								<div className="text-reset notification-item d-block dropdown-item">
									<div className="d-flex">
										<img
											src={avatar2}
											className="me-3 rounded-circle avatar-xs"
											alt="user-pic"
										/>
										<div className="flex-grow-1">
											<div className="stretched-link">
												<h6 className="mt-0 mb-1 fs-13 fw-semibold">
													Angela Bernier
												</h6>
											</div>
											<div className="fs-13 text-muted">
												<p className="mb-1">
													Answered to your comment on the cash flow forecast's
													graph 🔔.
												</p>
											</div>
											<p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
												<span>
													<i className="mdi mdi-clock-outline"></i> 2 hrs ago
												</span>
											</p>
										</div>
										<div className="px-2 fs-15 z-2">
											<i
												className="mdi mdi-delete text-danger fs-20 cursor-pointer"
												onClick={() => {}}></i>
										</div>
									</div>
								</div>
							</SimpleBar>
						)}
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
};

export default NotificationDropdown;
