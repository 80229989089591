import { BASE_API } from './constants';

// authentication
export const LOGIN_API = `${BASE_API}/auth/login`;
export const USER_API = `${BASE_API}/auth/user`;
export const SET_PASSWORD_API = `${BASE_API}/auth/set-password/:id`;
export const LOGOUT_API = `${BASE_API}/auth/logout`;

// utility
export const FETCH_COUTRIES_API = `${BASE_API}/utility/countries`;
export const FETCH_STATES_API = `${BASE_API}/utility/states/:country_id`;
export const FETCH_STATISTICS_API = `${BASE_API}/utility/statistics`;
export const SEND_EMAIL_API = `${BASE_API}/utility/send-mail`;
export const UPLOAD_IMAGE_API = `${BASE_API}/utility/upload-image`;

// settings
export const FETCH_SETTINGS_API = `${BASE_API}/settings`;
export const SAVE_ELECTION_SETTINGS_API = `${BASE_API}/settings`;

// roles
export const FETCH_ROLES_API = `${BASE_API}/roles`;
export const CREATE_ROLE_API = `${BASE_API}/roles`;
export const UPDATE_ROLE_API = `${BASE_API}/roles/:id`;
export const ENABLE_ROLE_API = `${BASE_API}/roles/:id/enable`;
export const DISABLE_ROLE_API = `${BASE_API}/roles/:id/disable`;
export const DELETE_ROLE_API = `${BASE_API}/roles/:id`;
export const RESTORE_ROLE_API = `${BASE_API}/roles/:id/restore`;
export const SET_ROLE_PERMISSIONS_API = `${BASE_API}/roles/:id/sync-permissions`;

// permissions
export const FETCH_PERMISSIONS_API = `${BASE_API}/permissions`;
export const CREATE_PERMISSION_API = `${BASE_API}/permissions`;
export const UPDATE_PERMISSION_API = `${BASE_API}/permissions/:id`;
export const DELETE_PERMISSION_API = `${BASE_API}/permissions/:id`;

// audit logs
export const FETCH_AUDITLOGS_API = `${BASE_API}/audit-logs`;

// election offices
export const FETCH_OFFICES_API = `${BASE_API}/offices`;
export const CREATE_OFFICE_API = `${BASE_API}/offices`;
export const UPDATE_OFFICE_API = `${BASE_API}/offices/:id`;
export const DELETE_OFFICE_API = `${BASE_API}/offices/:id`;
export const RESTORE_OFFICE_API = `${BASE_API}/offices/:id/restore`;

// users
export const FETCH_USERS_API = `${BASE_API}/users`;
export const FETCH_PROFILE_API = `${BASE_API}/users/:id`;
export const CREATE_USER_API = `${BASE_API}/users`;
export const UPDATE_USER_API = `${BASE_API}/users/:id`;
export const UPDATE_PROFILE_API = `${BASE_API}/users/:id/update-profile`;
export const DELETE_USER_API = `${BASE_API}/users/:id`;
export const RESTORE_USER_API = `${BASE_API}/users/:id/restore`;
export const DISABLE_USER_API = `${BASE_API}/users/:id/disable`;
export const ENABLE_USER_API = `${BASE_API}/users/:id/enable`;
export const CHANGE_PASSWORD_API = `${BASE_API}/users/:id/change-password`;
export const DEACTIVATE_ACCOUNT_API = `${BASE_API}/users/:id/deactivate`;
export const CHANGE_USER_ROLE_API = `${BASE_API}/users/:id/change-role`;
export const IMPORT_USERS_API = `${BASE_API}/users/import/file`;
export const RESEND_EMAIL_API = `${BASE_API}/users/:id/resend-email`;

// election candidates
export const FETCH_CANDIDATES_API = `${BASE_API}/candidates`;
export const CREATE_CANDIDATE_API = `${BASE_API}/candidates`;
export const UPDATE_CANDIDATE_API = `${BASE_API}/candidates/:id`;
export const DELETE_CANDIDATE_API = `${BASE_API}/candidates/:id`;
export const RESTORE_CANDIDATE_API = `${BASE_API}/candidates/:id/restore`;

// election
export const FETCH_RESULTS_API = `${BASE_API}/result`;
export const CANCEL_VOTE_API = `${BASE_API}/vote/:id`;
