import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import LocalStorage from './services/storage';
import configureAppStore from './redux/store';
import { fetchUtilities } from './redux/slices/utility';
import { TOKEN_COOKIE } from './services/constants';
import { fetchAuthUser } from './redux/slices/user';
import { togglePreloading } from './redux/slices/setting';

import '@lordicon/element/release/lordicon';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './assets/scss/themes.scss';

const storage = new LocalStorage();
const store = configureAppStore();

store.dispatch(fetchUtilities());

if (storage.getItem(TOKEN_COOKIE)) {
	store.dispatch(fetchAuthUser());
} else {
	store.dispatch(togglePreloading(false));
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
