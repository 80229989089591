import React, { useEffect, useState } from 'react';
import { checkPermission } from '../services/utilities';
import { useSelector } from 'react-redux';

const SidebarMenuData = () => {
	const [isCurrentState, setIsCurrentState] = useState('Dashboard');

	const permissions = useSelector(state => state.user.permissions);

	useEffect(() => {
		document.body.classList.remove('twocolumn-panel');
		if (
			isCurrentState === 'Dashboard' ||
			isCurrentState === 'Candidates' ||
			isCurrentState === 'ElectionResult' ||
			isCurrentState === 'Users' ||
			isCurrentState === 'Officials' ||
			isCurrentState === 'AuditLog' ||
			isCurrentState === 'Settings'
		) {
			document.body.classList.add('twocolumn-panel');
		}
	}, [isCurrentState]);

	const menuItems = [
		{
			label: 'Main Home',
			isHeader: true,
			permission: checkPermission(permissions, 'can-see-dashboard'),
		},
		{
			id: 'dashboard',
			label: 'Dashboard',
			icon: 'ri-dashboard-line',
			link: '/',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Dashboard');
			},
			permission: checkPermission(permissions, 'can-see-dashboard'),
		},
		{
			label: 'Election',
			isHeader: true,
			permission:
				checkPermission(permissions, 'can-see-candidates') ||
				checkPermission(permissions, 'can-see-election-result'),
		},
		{
			id: 'candidates',
			label: 'Candidates',
			icon: 'ri-map-pin-line',
			link: '/candidates',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Candidates');
			},
			permission: checkPermission(permissions, 'can-see-candidates'),
		},
		{
			id: 'election_result',
			label: 'Election Result',
			icon: 'ri-map-pin-line',
			link: '/election-result',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('ElectionResult');
			},
			permission: checkPermission(permissions, 'can-see-election-result'),
		},
		{
			label: 'Users',
			isHeader: true,
			permission:
				checkPermission(permissions, 'can-see-users') ||
				checkPermission(permissions, 'can-see-officials'),
		},
		{
			id: 'users',
			label: 'Voters',
			icon: 'ri-user-3-line',
			link: '/users',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Users');
			},
			permission: checkPermission(permissions, 'can-see-users'),
		},
		{
			id: 'officials',
			label: 'Officials',
			icon: 'ri-group-line',
			link: '/officials',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Officials');
			},
			permission: checkPermission(permissions, 'can-see-officials'),
		},
		{
			label: 'System Settings',
			isHeader: true,
			permission:
				checkPermission(permissions, 'can-see-audit-log') ||
				checkPermission(permissions, 'can-see-change-password') ||
				checkPermission(permissions, 'can-see-election-settings') ||
				checkPermission(permissions, 'can-see-user-roles') ||
				checkPermission(permissions, 'can-see-user-permissions') ||
				checkPermission(permissions, 'can-see-offices') ||
				checkPermission(permissions, 'can-see-mailer'),
		},
		{
			id: 'audit',
			label: 'Audit Log',
			icon: 'ri-folders-line',
			link: '/audit-log',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('AuditLog');
			},
			permission: checkPermission(permissions, 'can-see-audit-log'),
		},
		{
			id: 'settings',
			label: 'Settings',
			icon: 'ri-settings-3-line',
			link: '/settings',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Settings');
			},
			permission:
				checkPermission(permissions, 'can-see-change-password') ||
				checkPermission(permissions, 'can-see-election-settings') ||
				checkPermission(permissions, 'can-see-user-roles') ||
				checkPermission(permissions, 'can-see-user-permissions') ||
				checkPermission(permissions, 'can-see-offices') ||
				checkPermission(permissions, 'can-see-mailer'),
		},
	];
	return <>{menuItems}</>;
};

export default SidebarMenuData;
