import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import { PREVIOUS_PATH } from '../services/constants';
import LocalStorage from '../services/storage';

const storage = new LocalStorage();

const CheckLoggedIn = ({ children }) => {
	const { user } = useAuth();
	const location = useLocation();

	const path = location.pathname.split('/').pop();

	if (user && path === 'login' && user.set_password === 1) {
		const previous_path = storage.getItem(PREVIOUS_PATH);
		const new_path = previous_path === '/logout' ? '/' : previous_path;
		return <Navigate to={new_path || '/'} state={{ from: location }} replace />;
	}

	return <>{children}</>;
};

export default CheckLoggedIn;
