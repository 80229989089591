import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AppRoutes from './routes';
import LocalStorage from './services/storage';
import { PREVIOUS_PATH } from './services/constants';
import Preloader from './views/Preloader';
import AuthProvider from './provider/AuthProvider';

const storage = new LocalStorage();

const App = () => {
	const preloading = useSelector(state => state.setting.preloading);

	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (
			location.state?.from?.pathname !== '/login' &&
			location.state?.from?.pathname !== '/set-password' &&
			location.pathname !== '/login' &&
			location.pathname !== '/set-password' &&
			location.pathname !== '/logout'
		) {
			const pathname = location.pathname;
			const query = location.search !== '' ? location.search : '';
			const hash = location.hash !== '' ? location.hash : '';

			const path = `${pathname}${query}${hash}`;
			storage.setItem(PREVIOUS_PATH, path);
		}
	}, [dispatch, location]);

	return preloading ? (
		<Preloader />
	) : (
		<AuthProvider>
			<AppRoutes />
		</AuthProvider>
	);
};

export default App;
