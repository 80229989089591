import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { APP_NAME } from '../services/constants';

const GuestLayout = ({ children }) => {
	const layoutThemeType = useSelector(state => state.layout.layoutThemeType);
	const layoutModeType = useSelector(state => state.layout.layoutModeType);

	const location = useLocation();
	const isSetProfile = useMemo(
		() => location.pathname.split('/').pop() === 'set-profile',
		[location.pathname]
	);

	useEffect(() => {
		document.body.setAttribute('data-theme', layoutThemeType);

		if (layoutModeType === 'dark') {
			document.body.setAttribute('data-bs-theme', 'dark');
		} else {
			document.body.setAttribute('data-bs-theme', 'light');
		}
		return () => {
			document.body.removeAttribute('data-bs-theme');
			document.body.removeAttribute('data-theme');
		};
	}, [layoutModeType, layoutThemeType]);

	return (
		<div>
			<div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
				<div className="bg-overlay"></div>

				<div className="auth-page-content overflow-hidden pt-lg-5">
					<div className="container">
						<div className="row justify-content-center">
							<div className={isSetProfile ? 'col-lg-7' : 'col-lg-5'}>
								<div className="card overflow-hidden">{children}</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="footer">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="text-center">
									<p className="mb-0 text-dark">
										&copy; {new Date().getFullYear()} {APP_NAME}. App by{' '}
										<a className="text-primary">NerdLab</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
	);
};

export default GuestLayout;
