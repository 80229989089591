import React, { useState } from 'react';
import { useAuth } from '../hooks/auth';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DropdownToggle from '../scripts/dropdown/DropdownToggle';
import DropdownMenu from '../scripts/dropdown/DropdownMenu';
import DropdownItem from '../scripts/dropdown/DropdownItem';
import Dropdown from '../scripts/dropdown';
import { checkPermission, notifyWithIcon } from '../services/utilities';
import {
	PREVIOUS_PATH,
	TOKEN_COOKIE,
	TOKEN_EXPIRATION,
} from '../services/constants';
import { request } from '../services/http';
import { LOGOUT_API } from '../services/api';
import { signOut } from '../redux/slices/user';
import LocalStorage from '../services/storage';

const storage = new LocalStorage();

const ProfileDropdown = () => {
	const [isProfileDropdown, setIsProfileDropdown] = useState(false);

	const dispatch = useDispatch();
	const { user } = useAuth();
	const permissions = useSelector(state => state.user.permissions);

	const toggleProfileDropdown = () => {
		setIsProfileDropdown(!isProfileDropdown);
	};

	const doLogout = async () => {
		try {
			const config = { method: 'POST', body: {} };
			const rs = await request(LOGOUT_API, config);
			dispatch(signOut());
			storage.removeItem(TOKEN_COOKIE);
			storage.removeItem(TOKEN_EXPIRATION);
			storage.removeItem(PREVIOUS_PATH);
			notifyWithIcon('success', rs.message);
		} catch (e) {
			notifyWithIcon('error', e.message);
		}
	};

	return (
		<Dropdown
			isOpen={isProfileDropdown}
			toggle={toggleProfileDropdown}
			className="ms-sm-3 header-item topbar-user">
			<DropdownToggle tag="button" type="button" className="btn">
				<span className="d-flex align-items-center">
					<img
						className="rounded-circle header-profile-user"
						src={user?.profile?.avatar}
						alt="Header Avatar"
					/>
					<span className="text-start ms-xl-2">
						<span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text text-capitalize">
							{user?.profile?.name || ''}
						</span>
						<span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
							{user?.roles?.map(r => r.description).join(', ') || ''}
						</span>
					</span>
				</span>
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu-end">
				<h6 className="dropdown-header">
					Welcome {user?.profile?.firstname || ''}!
				</h6>
				<DropdownItem className="p-0" tag="div">
					<Link to={`/account/${user?.public_id}`} className="dropdown-item">
						<i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
						<span className="align-middle">Profile</span>
					</Link>
				</DropdownItem>
				<div className="dropdown-divider"></div>
				{(checkPermission(permissions, 'can-see-change-password') ||
					checkPermission(permissions, 'can-see-election-settings') ||
					checkPermission(permissions, 'can-see-user-roles') ||
					checkPermission(permissions, 'can-see-user-permissions') ||
					checkPermission(permissions, 'can-see-offices') ||
					checkPermission(permissions, 'can-see-mailer')) && (
					<DropdownItem className="p-0">
						<Link to="/settings" className="dropdown-item">
							<i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{' '}
							<span className="align-middle">Settings</span>
						</Link>
					</DropdownItem>
				)}
				<DropdownItem className="p-0" tag="div">
					<Link onClick={() => doLogout()} className="dropdown-item">
						<i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
						<span className="align-middle" data-key="t-logout">
							Logout
						</span>
					</Link>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default ProfileDropdown;
