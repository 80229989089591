import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import GuestLayout from '../layouts/GuestLayout';
import Layout from '../layouts/Layout';
import AuthProtected from './AuthProtected';
import CheckLoggedIn from './CheckLoggedIn';
import NoMatch from '../views/NoMatch';
import Preloader from '../views/Preloader';

const Login = lazy(() => import('../views/authentication/Login'));
const SetPassword = lazy(() => import('../views/authentication/SetPassword'));

const publicRoutes = [
	{ path: '/login', component: <Login /> },
	{ path: '/set-password', component: <SetPassword /> },
];

const Dashboard = lazy(() => import('../views/Dashboard'));
const ElectionResult = lazy(() => import('../views/ElectionResult'));
const Candidates = lazy(() => import('../views/users/Candidates'));
const Users = lazy(() => import('../views/users/Users'));
const Officials = lazy(() => import('../views/users/Officials'));
const Profile = lazy(() => import('../views/users/Profile'));
const AuditLog = lazy(() => import('../views/AuditLog'));
const Settings = lazy(() => import('../views/settings/Index'));

const authProtectedRoutes = [
	{ path: '/', component: <Dashboard /> },
	{ path: '/candidates', component: <Candidates /> },
	{ path: '/election-result', component: <ElectionResult /> },
	{ path: '/users', component: <Users /> },
	{ path: '/officials', component: <Officials /> },
	{ path: '/account/:_id', component: <Profile /> },
	{ path: '/audit-log', component: <AuditLog /> },
	{ path: '/settings/*', component: <Settings /> },
];

const Index = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<AuthProtected>
						<Layout />
					</AuthProtected>
				}>
				{authProtectedRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={<>{route.component}</>}
						key={idx}
						exact={true}
					/>
				))}
			</Route>
			{publicRoutes.map((route, idx) => (
				<Route
					path={route.path}
					element={
						<Suspense fallback={<Preloader />}>
							<CheckLoggedIn>
								<GuestLayout>{route.component}</GuestLayout>
							</CheckLoggedIn>
						</Suspense>
					}
					key={idx}
					exact={true}
				/>
			))}
			<Route path="not-found" element={<NoMatch />} />
			<Route path="*" element={<NoMatch />} />
		</Routes>
	);
};

export default Index;
