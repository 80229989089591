export const APP_DEBUG = process.env.REACT_APP_DEBUG === 'true';
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_DESCRIPTION = process.env.REACT_APP_NAME;

export const BASE_API = process.env.REACT_APP_BASE_API;

export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;

export const TOKEN_COOKIE = '__ffecookie';
export const TOKEN_EXPIRATION = '__ffecexpire';
export const PREVIOUS_PATH = '__ffepath';

export const LIMITS = APP_DEBUG ? [1, 2, 4, 8, 12, 16, 20] : [8, 12, 16];

export const LIMIT = APP_DEBUG ? 4 : 8;
export const PAGINATE = {
	total: 0,
	per_page: LIMIT,
	current_page: 1,
	last_page: 0,
};

export const DATETIME = 'DD-MMM-YYYY h:mma';
export const DATE = 'DD-MMM-YYYY';

export const ELLIPSIS = 'ellipsis';

export const START_YEAR = 2024;

export const GENDERS = [
	{ label: 'Male', value: 'Male' },
	{ label: 'Female', value: 'Female' },
];

export const COLORS = [
	'primary',
	'success',
	'warning',
	'info',
	'danger',
	'secondary',
];
