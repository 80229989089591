import settingReducer from './setting';
import userReducer from './user';
import layoutReducer from './layout';
import utilityReducer from './utility';

const reducers = {
	setting: settingReducer,
	layout: layoutReducer,
	user: userReducer,
	utility: utilityReducer,
};

export default reducers;
