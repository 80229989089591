import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { Outlet } from 'react-router-dom';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import {
	changeLayout,
	changeLayoutMode,
	changeLayoutPosition,
	changeLayoutTheme,
	changeLayoutThemeColor,
	changeLayoutWidth,
	changeLeftsidebarSizeType,
	changeLeftsidebarViewType,
	changeSidebarImageType,
	changeSidebarTheme,
	changeSidebarVisibility,
	changeTopbarTheme,
} from '../redux/slices/layout';
import Splash from '../views/Splash';
import { leftSidebarSizeTypes } from '../services/layout';

const Layout = () => {
	const [loaded, setLoaded] = useState(false);
	const [headerClass, setHeaderClass] = useState('');

	const dispatch = useDispatch();

	const selectLayoutState = state => state.layout;
	const selectLayoutProperties = createSelector(selectLayoutState, layout => ({
		layoutType: layout.layoutType,
		layoutThemeType: layout.layoutThemeType,
		layoutThemeColorType: layout.layoutThemeColorType,
		leftSidebarType: layout.leftSidebarType,
		layoutModeType: layout.layoutModeType,
		layoutWidthType: layout.layoutWidthType,
		layoutPositionType: layout.layoutPositionType,
		topbarThemeType: layout.topbarThemeType,
		leftSidebarSizeType: layout.leftSidebarSizeType,
		leftSidebarViewType: layout.leftSidebarViewType,
		leftSidebarImageType: layout.leftSidebarImageType,
		preloader: layout.preloader,
		sidebarVisibilitytype: layout.sidebarVisibilitytype,
	}));

	const {
		layoutType,
		leftSidebarType,
		layoutModeType,
		layoutWidthType,
		layoutPositionType,
		topbarThemeType,
		leftSidebarSizeType,
		leftSidebarViewType,
		leftSidebarImageType,
		// preloader,
		sidebarVisibilitytype,
		layoutThemeType,
		layoutThemeColorType,
	} = useSelector(selectLayoutProperties);

	useEffect(() => {
		if (
			layoutType ||
			leftSidebarType ||
			layoutModeType ||
			layoutWidthType ||
			layoutPositionType ||
			topbarThemeType ||
			leftSidebarSizeType ||
			leftSidebarViewType ||
			leftSidebarImageType ||
			sidebarVisibilitytype ||
			layoutThemeType ||
			layoutThemeColorType
		) {
			window.dispatchEvent(new Event('resize'));
			dispatch(changeLeftsidebarViewType(leftSidebarViewType));
			dispatch(changeLeftsidebarSizeType(leftSidebarSizeType));
			dispatch(changeSidebarTheme(leftSidebarType));
			dispatch(changeLayoutThemeColor(layoutThemeColorType));
			dispatch(changeLayoutTheme(layoutThemeType));
			dispatch(changeLayoutMode(layoutModeType));
			dispatch(changeLayoutWidth(layoutWidthType));
			dispatch(changeLayoutPosition(layoutPositionType));
			dispatch(changeTopbarTheme(topbarThemeType));
			dispatch(changeLayout(layoutType));
			dispatch(changeSidebarImageType(leftSidebarImageType));
			dispatch(changeSidebarVisibility(sidebarVisibilitytype));
		}
	}, [
		dispatch,
		layoutModeType,
		layoutPositionType,
		layoutThemeColorType,
		layoutThemeType,
		layoutType,
		layoutWidthType,
		leftSidebarImageType,
		leftSidebarType,
		leftSidebarViewType,
		leftSidebarSizeType,
		sidebarVisibilitytype,
		topbarThemeType,
	]);

	const onChangeLayoutMode = value => {
		if (changeLayoutMode) {
			dispatch(changeLayoutMode(value));
		}
	};

	const onChangeLeftSidebarViewType = value => {
		if (changeLeftsidebarViewType) {
			dispatch(changeLeftsidebarViewType(value));
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollNavigation, true);
	});

	function scrollNavigation() {
		var scrollup = document.documentElement.scrollTop;
		if (scrollup > 50) {
			setHeaderClass('topbar-shadow');
		} else {
			setHeaderClass('');
		}
	}

	window.onscroll = function () {
		scrollFunction();
	};

	const scrollFunction = () => {
		const element = document.getElementById('back-to-top');
		if (element) {
			if (
				document.body.scrollTop > 100 ||
				document.documentElement.scrollTop > 100
			) {
				element.style.display = 'block';
			} else {
				element.style.display = 'none';
			}
		}
	};

	useEffect(() => {
		if (
			sidebarVisibilitytype === 'show' ||
			layoutType === 'vertical' ||
			layoutType === 'twocolumn'
		) {
			document.querySelector('.hamburger-icon')?.classList.remove('open');
		} else {
			document.querySelector('.hamburger-icon') &&
				document.querySelector('.hamburger-icon').classList.add('open');
		}

		if (!loaded && leftSidebarSizeType === leftSidebarSizeTypes.SMALLICON) {
			setLoaded(true);
			setTimeout(
				() => document.querySelector('.hamburger-icon')?.classList.add('open'),
				100
			);
		}
	}, [sidebarVisibilitytype, layoutType, loaded, leftSidebarSizeType]);

	const toTop = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	};

	return (
		<div id="layout-wrapper">
			<Header
				headerClass={headerClass}
				layoutModeType={layoutModeType}
				onChangeLayoutMode={onChangeLayoutMode}
				leftSidebarView={leftSidebarViewType}
				onChangeLeftSidebarViewType={onChangeLeftSidebarViewType}
			/>
			<Sidebar layoutType={layoutType} />
			<div className="main-content">
				<div className="page-content">
					<Suspense fallback={<Splash />}>
						<Outlet />
					</Suspense>
				</div>
				<Footer />
			</div>

			<button
				onClick={() => toTop()}
				className="btn btn-danger btn-icon"
				id="back-to-top">
				<i className="ri-arrow-up-line"></i>
			</button>
		</div>
	);
};

export default Layout;
