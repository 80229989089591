import notification from 'antd/es/notification';
import moment from 'moment';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { confirmAlert } from 'react-confirm-alert';

import { LIMIT } from './constants';

export const isUnset = o => typeof o === 'undefined' || o === null;

export function encodeValue(val) {
	if (typeof val === 'string') {
		return val;
	}

	return JSON.stringify(val);
}

export function decodeValue(val) {
	if (typeof val === 'string') {
		try {
			return JSON.parse(val);
		} catch (_) {}
	}

	return val;
}

export const changeHTMLAttribute = (attribute, value) => {
	if (document.documentElement) {
		document.documentElement.setAttribute(attribute, value);
	}
	return true;
};

export const error = meta => (meta.touched && meta.error ? 'is-invalid' : '');

export function resetForm(values, form) {
	Object.keys(values).forEach(key => {
		form.change(key, undefined);
		form.resetFieldState(key);
	});
}

export function updateImmutable(list, payload) {
	const data = list.find(d => d.id === payload.id);
	if (data) {
		const index = list.findIndex(d => d.id === payload.id);

		return [
			...list.slice(0, index),
			{ ...data, ...payload },
			...list.slice(index + 1),
		];
	}

	return list;
}

export const notifyWithIcon = (type, description, duration = 4.5) => {
	notification[type]({ message: type, description, duration });
};

export const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const antIconSync = <SyncOutlined style={{ fontSize: 24 }} spin />;

export const checkPermission = (permissions, permission) => {
	return permissions.find(p => p?.name === permission);
};

export function formatDate(date, format = 'DD-MMM-YYYY') {
	if (!date) {
		return '--';
	}

	return moment(date).format(format);
}

export function confirmAction(action, payload, type, alertText, alertHead) {
	const icons = {
		delete: 'gsqxdxog',
		restore: 'pyzlgcfb',
		export: 'nocovwne',
		import: 'dxnllioo',
		disable: 'dykoqszm',
		enable: 'cgzlioyf',
		approve: 'cgzlioyf',
		decline: 'dykoqszm',
		completed: 'cgzlioyf',
		confirm: 'usownftb',
		cancel: 'dykoqszm',
	};

	confirmAlert({
		customUI: ({ onClose }) => {
			const onClick = async () => {
				action(payload);
				onClose();
			};

			return (
				<div className="custom-ui p-5 text-center">
					{icons[type] && (
						<lord-icon
							src={`https://cdn.lordicon.com/${icons[type]}.json`}
							trigger="loop"
							colors="primary:#0ab39c,secondary:#f06548"
							style={{ width: '100px', height: '100px' }}></lord-icon>
					)}
					<div className="mt-4 text-center">
						<h4>{alertHead ? alertHead : 'Are you sure?'}</h4>
						<p className="text-muted fs-15 mb-4">
							{alertText ? alertText : 'You want to delete this item'}
						</p>
						<div className="hstack gap-2 justify-content-center remove">
							<button className="btn btn-danger" onClick={onClose}>
								No
							</button>
							<button className="btn btn-success" onClick={onClick}>
								Yes
							</button>
						</div>
					</div>
				</div>
			);
		},
	});
}

export const getQueryString = query => {
	let queries = [];

	if (query.get('length')) {
		queries = [...queries, `length=${query.get('length')}`];
	}

	if (query.get('q')) {
		queries = [...queries, `q=${query.get('q')}`];
	}

	return queries.join('&');
};

export const changeLimit = (e, query, location, navigate) => {
	let queries = [];

	if (Number(e.target.value) !== LIMIT) {
		queries = [...queries, `length=${e.target.value}`];
	}

	if (query.get('q')) {
		queries = [...queries, `q=${query.get('q')}`];
	}

	const qs = queries.length > 0 ? `?${queries.join('&')}` : '';

	navigate(`${location.pathname}${qs}`);
};

export const doSearch = (searchTerm, query, location, navigate) => {
	let queries = [];

	if (query.get('length')) {
		queries = [...queries, `length=${query.get('length')}`];
	}

	if (searchTerm && searchTerm !== '') {
		queries = [...queries, `q=${searchTerm}`];
	}

	navigate(`${location.pathname}${`?${queries.join('&')}`}`);
};

export const doClearSearch = (query, location, navigate) => {
	let queries = [];

	if (query.get('length')) {
		queries = [...queries, `length=${query.get('length')}`];
	}

	const qs = queries.length > 0 ? `?${queries.join('&')}` : '';

	navigate(`${location.pathname}${qs}`);
};

export const getChartColorsArray = chartId => {
	const chartElement = document.getElementById(chartId);
	if (chartElement !== null) {
		const colorAttr =
			'data-colors' +
			('-' + document.documentElement.getAttribute('data-theme') ?? '');
		let colors =
			chartElement.getAttribute(colorAttr) ??
			chartElement.getAttribute('data-colors');

		if (colors) {
			colors = JSON.parse(colors);

			return colors.map(value => {
				const newValue = value.replace(' ', '');

				if (newValue.indexOf(',') === -1) {
					const color = getComputedStyle(
						document.documentElement
					).getPropertyValue(newValue);

					if (color) {
						return color;
					} else {
						return newValue;
					}
				} else {
					const val = value.split(',');

					if (val.length === 2) {
						let rgbaColor = getComputedStyle(
							document.documentElement
						).getPropertyValue(val[0]);
						rgbaColor = `rgba(${rgbaColor},${val[1]})`;
						return rgbaColor;
					} else {
						return newValue;
					}
				}
			});
		} else {
			console.warn('data-colors attributes not found on', chartId);
		}
	}

	return undefined;
};

export const randomNumber = function (min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
};

export const groupBy = function (xs, key) {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};
