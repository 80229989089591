import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import { request } from '../services/http';
import { LOGOUT_API } from '../services/api';
import { signOut } from '../redux/slices/user';
import LocalStorage from '../services/storage';
import {
	PREVIOUS_PATH,
	TOKEN_COOKIE,
	TOKEN_EXPIRATION,
} from '../services/constants';

const storage = new LocalStorage();

const AuthProtected = ({ children }) => {
	const location = useLocation();

	const preloading = useSelector(state => state.setting.preloading);

	const { user } = useAuth();
	const dispatch = useDispatch();

	const doLogout = useCallback(async () => {
		try {
			await request(LOGOUT_API, { method: 'POST', body: {} });
		} catch (e) {}
		dispatch(signOut());
		storage.removeItem(TOKEN_COOKIE);
		storage.removeItem(TOKEN_EXPIRATION);
		storage.removeItem(PREVIOUS_PATH);
	}, [dispatch]);

	useEffect(() => {
		if (!user && preloading) {
			doLogout();
		}
	}, [doLogout, preloading, user]);

	if (!user) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <>{children}</>;
};

export default AuthProtected;
